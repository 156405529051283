import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const articles = [
  {
    title: "Exploring the Electronic Signatures Ecosystem: Digitised Signature, Electronic Signatures, Digital Signatures and Aadhaar eSigns",
    description: "The term 'mark' extends to the shape of the goods, packaging, and color combinations. Service marks can now be registered.",
    category: "Intellectual Property Management",
    date: "Jul 1, 2024",
    readTime: "6 min read",
    author: "Kuldeep Patel",
    link: "/blog1", // Link to blog1
  },
  {
    title: "Unlocking Efficiency: The Power of Web Signing Solutions",
    description: "In today's digital age, businesses are constantly seeking ways to streamline operations, enhance productivity, and improve overall efficiency.",
    category: "Legal operations",
    date: "Jul 15, 2024",
    readTime: "3 min read",
    author: "Kuldeep Patel",
    link: "/blog2", // Link to blog2
  },
  {
    title: "Aadhaar eSign: Facilitating Secure and Legally Compliant Digital Signatures",
    description: "In India, Aadhaar eSign is a significant initiative aimed at simplifying and securing digital transactions through the use of Aadhaar-based digital signatures.",
    category: "Cybersecurity",
    date: "Jul 29, 2024",
    readTime: "4 min read",
    author: "Kuldeep Patel",
    link: "/blog3", // Link to blog3
  },
  {
    title: "Extensive Audit Trail with Advanced Signing Solutions",
    description: "In today&#39;s interconnected digital landscape, maintaining transparency and accountability is crucial for businesses of all sizes.",
    category: "Cybersecurity",
    date: "Aug 12, 2024",
    readTime: "4 min read",
    author: "Kuldeep Patel",
    link: "/blog4", // Link to blog4
  },
  {
    title: "Understanding the Data Protection (DPDP) Act: Consent and Compliances",
    description: "In an increasingly digital world where personal data is a valuable commodity, protecting individuals'; privacy has become a paramount concern.",
    category: "Cybersecurity",
    date: "Aug 26, 2024",
    readTime: "4 min read",
    author: "Kuldeep Patel",
    link: "/blog5", // Link to blog5
  },
  {
    title: "Digital Signatures: Safeguarding Electronic Transactions in India",
    description: "In the realm of digital transactions, where the exchange of information and contracts happens at the speed of light, ensuring authenticity and security is paramount.",
    category: "Cybersecurity",
    date: "Sep 9, 2024",
    readTime: "4 min read",
    author: "Kuldeep Patel",
    link: "/blog6", // Link to blog6
  },
  {
    title: "Integrating Signing Solutions into an ERP: A Guide for Enhanced Efficiency and Security",
    description: "Signing solutions encompass a range of electronic signature technologies designed to replace traditional paper-based signatures with secure, legally-binding digital alternatives.",
    category: "Cybersecurity",
    date: "Sep 23, 2024",
    readTime: "4 min read",
    author: "Kuldeep Patel",
    link: "/blog7", // Link to blog7
  },
  {
    title: "The Role of Digital Contracts in Finance Operations",
    description: "In the realm of finance, where accuracy, speed, and compliance are paramount, the adoption of digital contracts has emerged as a transformative practice.",
    category: "Cybersecurity",
    date: "Oct 7, 2024",
    readTime: "4 min read",
    author: "Kuldeep Patel",
    link: "/blog8", // Link to blog8
  }
];

const Articles = () => {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (current) {
      current.scrollBy({
        left: direction === 'left' ? -300 : 300,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="relative bg-white text-white p-4 md:p-8">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-center mb-6 text-gray-900">Blogs</h2>

      <div className="flex overflow-x-scroll space-x-4 sm:space-x-6 pb-8" ref={scrollRef}>
        {articles.map((article, index) => (
          <Link to={article.link} key={index}>
            <div className="bg-gray-900 p-4 sm:p-6 rounded-lg w-64 sm:w-72 md:w-80 h-72 sm:h-80 flex-shrink-0 hover:shadow-lg transition-shadow duration-300">
              <div className="h-full flex flex-col justify-between">
                <div>
                  <h3 className="text-base sm:text-lg md:text-xl font-semibold mt-2 text-gray-100">{article.title}</h3>
                  <p className="text-sm sm:text-base text-gray-300 mt-2">{article.description}</p>
                </div>
                <div className="mt-4 text-gray-400 text-xs sm:text-sm">
                  <span>{article.author}</span> &bull; <span>{article.date}</span> &bull; <span>{article.readTime}</span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Left Arrow */}
      <button
        onClick={() => scroll('left')}
        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-full shadow-lg hover:bg-gray-700 focus:outline-none"
      >
        <FaArrowLeft className="text-white text-xl" />
      </button>

      {/* Right Arrow */}
      <button
        onClick={() => scroll('right')}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-full shadow-lg hover:bg-gray-700 focus:outline-none"
      >
        <FaArrowRight className="text-white text-xl" />
      </button>
    </div>
  );
};

export default Articles;